import React from "react"

import Layout from "../components/layout";

const AboutPage = () => (
    <Layout pageTitle="About">
        <h1>This is where we show off our games</h1>
        <p>We like to think they are better.</p>
    </Layout>
)

export default AboutPage